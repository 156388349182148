<template>
  <div class="Dashboard" v-if="userProfile">
    <h1 class="title">Dashboard</h1>

    <div class="columns">
      <div class="column is-one-quarter">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <h2>Howdy, {{userProfile.first_name}}</h2>
              <p>This application is still a work in progress, so some things might not work as expected. If you come across any errors, use the <strong>Report Problem</strong> button on the top right of the screen or <a href="mailto:charlie@moscadesign.com">send me an email.</a></p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-third">
        <div class="card">
          <div class="card-content ">
            <div class="content">
              <h2>Database Synchronized</h2>
              <p>The database has been re-synchronized.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: 'Dashboard',
  computed: {
    ...mapGetters({
      userProfile: "User/getProfile"
    })
  },
  methods: {
    createToast() {
      this.$toast.create("Test", "", 5000)
    }
  }
}
</script>
<style scoped>
  .card {
    border: 5px solid #e2e2e2;
  }
</style>
