<template>
  <div class="column is-one-quarter searchProductCard">
    <router-link v-if="!loading && __product" :to="'/dashboard/listings/' + uid">
      <div class="card" v-if="!loading">
        <header class="card-header">
          <h2 class="card-header-title subtitle">{{__product.name}}</h2>
        </header>

        <div class="card-image">
          <figure class="image is-square">
            <img :src="__product.images && __product.images[0]? __product.images[0] : ''">
          </figure>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <div class="productMeta">
                <div class="mainMeta columns">
                  <div class="column">
                    <h3>SKU: {{__product.sku && __product.sku.length > 0? __product.sku : "N/A"}}</h3>
                  </div>
                  <div class="column has-text-right">
                    <h3>${{__product.price}}</h3>
                  </div>
                </div>
                <div class="additionalMeta">
                  <div class="attributes" v-if="__product.attributes && __product.attributes.length > 0 && __product.type == 'variation'">
                    <strong>Attributes</strong>
                    <ul>
                      <li v-for="attribute in __product.attributes">
                        {{attribute.name}}: {{attribute.option}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
 
              
            </div>
          </div>
        </div>
      </div>
      <div class="card" v-else>
        <div class="card-content">
          <Loader is-active="true" v-if="loading"></Loader>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader"
import Products from "@/api/ProductListings"

export default {
  name: 'Search Product Card',
  components: {
    Loader
  },
  data() {
    return {
      loading: true,
      _product: {}
    }
  },
  methods: {
    productResponse(response) {
      this._product = response
      this.loading = false
    }
  },
  computed: {
    __product: function() {
      if(this.product) {
        return this.product
      } else if(this._product) {
        return this._product
      }
    }
  },
  mounted: function() {
    if(this.fetch == true) {
      Products.getByUID(this.uid).then(this.productResponse).catch(error => {
        console.log(error)
      })
    } else {
      this.loading = false
    }
  },
  props: {
    product: Object,
    fetch: Boolean,
    uid: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .mainMeta {
    margin-bottom: 10px;
  }
  .attributes {
    border: 1px solid #ccc;
    padding: 10px;
  }

  .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #ddd;
  }

  .product-title {
    font-weight: 700;
  }

  .card {
    min-height: 200px;
    border: 1px solid #ddd;
  }

</style>
