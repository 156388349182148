<template>
  <div class="search">
    <div v-if="!loading && results.length > 0">
      <h1 class="title">Results for "{{searchQuery}}"</h1>
      
      <div class="columns is-multiline">

          <SearchProductCard :uid="result.objectID" :fetch="true" :showVariations="show_variations" :key="result.objectID + show_variations" v-for="result in results"></SearchProductCard>
      </div>
    </div>
    <div v-else-if="!loading && results.length <= 0" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;">
      <h2 class="is-size-2 text-danger"><i class="fas fa-file-excel"></i></h2>
      <h1 class="has-text-weight-normal">No results for <i>{{searchQuery}}</i></h1>
    </div>
    <div style="position: relative; height: 100%;" v-else>
      <Loader is-active="true"></Loader>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader"
import SearchProductCard from "@/components/dashboard/productListings/SearchProductCard"
import algoliasearch from 'algoliasearch/lite';

const client = algoliasearch('1XZQWYJKQ7', 'bd2962f7e534d667772ae599736173f1');
const index = client.initIndex('product_listings');

export default {
  name: 'Search',
  data() {
    return {
      loading: true,
      show_variations: true,
      results: []
    }
  },
  components: {
    Loader,
    SearchProductCard
  },
  computed: {
    searchQuery: function() {
      return this.$route.query.q
    }
  },
  methods: {
    queryResponse(response) {
      console.log(response)
        this.results = response.hits
        this.loading = false
    }
  },
  mounted: function() {
    console.log(this.$route)
    index.search(this.searchQuery, {
      hitsPerPage: 50
      }).then(this.queryResponse).catch(error => {
      console.log(error)
    })
  },
  watch: {
    "$route": function() {
      this.loading = true
      index.search(this.searchQuery, {
      }).then(this.queryResponse).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>
<style scoped>
  .search {
    height: 100%;
  }
</style>
